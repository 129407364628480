import SusTypography from "@components/SusTypography";
import { ViewSettings } from "@src/types";

const viewSettings: ViewSettings = {
  apiVersion: "v2",
  hasControlRoom: true,
  uiModules: ["Contracts", "CallOffs"],
  availableObjectClasses: ["Contract", "ContractPosition", "CallOff"],
  manualObjectCreation: false,
  tourSteps: [
    // Intro
    {
      content: (
        <>
          <SusTypography variant="h4" fontWeight="bold" mb={"25px"}>
            Welcome to the Dashboard tour
          </SusTypography>
          <SusTypography variant="body1">
            The following steps will give you an overview of the Dashboard.
          </SusTypography>
        </>
      ),
      target: "body",
      // Overlay
      // Navigationselemente
      // Layout
      placement: "center",
    },

    // 1. Site Steps
    // 2. Steps
    {
      content: "Navigate through the contract data here.",
      target: ".SideNavList-wrapper",
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "right",
    },
    // 4. Steps
    {
      content: "View your profile and adjust notifications and preferences.",
      target: ".userMenu",
      spotlightClicks: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "bottom",
    },
    // 5. Steps
    {
      content: "Contact support if you need help.",
      target: ".HelpMenue-Wrapper",
      spotlightClicks: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      disableScrolling: true,
      placement: "right",
    },

    // 2. Site Steps
    // 6. Steps
    {
      content: "Navigate to Control Room.",
      target: ".sidenavItem-control-room",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableScrolling: true,
      disableCloseOnEsc: true,
    },
    // 7. Steps
    {
      content: "The default version of the Control Room is displayed here.",
      target: ".ControlRoom-wrapper",
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 8. Steps
    {
      content:
        "Design your individual control room here later. Choose from different widgets and data visualisations.",
      target: ".Control-edit-Button",
      spotlightClicks: false,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 3. Site Steps
    // 9. Steps
    {
      content: "Navigate to Contracts.",
      target: ".sidenavItem-contracts",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableScrolling: true,
      disableCloseOnEsc: true,
    },
    // 10. Steps
    {
      content: "Filter Contracts by their current status.",
      target: ".Tabs-root-wrapper",
      spotlightClicks: true,
      disableScrolling: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 11. Steps
    {
      content: "Search for Contracts or any detail of a Contract.",
      target: ".SusTabContent .search-input",
      disableScrolling: true,
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 12. Steps
    {
      content: "View the most important details of a contract. Sort by any column.",
      target: ".MuiDataGrid-columnHeaders",
      disableScrolling: true,
      disableBeacon: true,
      placement: "bottom",
      disableOverlayClose: true,
      disableCloseOnEsc: true,
    },
    // 13. Steps
    {
      content: "Click on this Contract to view further details.",
      target: ".MuiDataGrid-row:first-of-type",
      nextStepOnClick: true,
      lazyTarget: true,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableCloseOnEsc: true,
    },
    // 4. Site Steps
    // 14. Steps
    {
      content: "All details of a Contract are displayed here.",
      target: ".contract-details-card",
      disableBeacon: true,
      disableOverlayClose: true,
      lazyTarget: true,
      placement: "right",
      disableCloseOnEsc: true,
    },
    // 15. Steps
    {
      content: "Time stamp showing the last data update.",
      target: ".contract-details-card .info-tooltip",
      lazyTarget: false,
      spotlightClicks: true,
      disableOverlayClose: true,
      offset: 10,
      placement: "right",
      disableCloseOnEsc: true,
    },
    // 16. Steps
    {
      content:
        "All positions of a Contract are displayed here. Click to view further details of a certain position. ",
      target: ".wrapper-positions-table",
      lazyTarget: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "top",
      disableCloseOnEsc: true,
    },
    // 17. Steps
    {
      content: "All Call-Offs that are linked to this Contract are displayed here.",
      target: ".wrapper-callOffs-table",
      lazyTarget: false,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "top",
      disableCloseOnEsc: true,
    },
    // 18. Steps
    {
      content: "Click on this position to view further details.",
      target: ".wrapper-positions-table .MuiDataGrid-row:first-of-type",
      nextStepOnClick: true,
      lazyTarget: false,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableCloseOnEsc: true,
    },
    // 5. Site Steps
    // 19. Steps
    {
      content: "All details of a contract position are displayed here.",
      target: ".contract-position-card",
      lazyTarget: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: "right",
      disableCloseOnEsc: true,
    },
    // 20. Steps
    {
      content: "Specific product details are displayed here.",
      target: ".product-details-card",
      disableOverlayClose: true,
      placement: "left",
      disableCloseOnEsc: true,
    },
    // 21. Steps
    {
      content: "Navigate again to Contracts.",
      target: ".sidenavItem-contracts",
      nextStepOnClick: true,
      lazyTarget: false,
      spotlightClicks: true,
      hideBackButton: false,
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      disableScrolling: true,
      disableCloseOnEsc: true,
    },
    {
      content: (
        <>
          <SusTypography variant="h4" fontWeight="bold" mb={"25px"}>
            Well done!
          </SusTypography>
          <SusTypography variant="body1" mb={"10px"}>
            Thank you for taking the tour, we hope you enjoy using the dashboard.
          </SusTypography>
          <SusTypography variant="body2">
            You can restart this tour by clicking Help & Contact in the menu on the left.
          </SusTypography>
        </>
      ),
      target: "body",
      placement: "center",
      hideFooter: false,
    },
  ],
  contract: {
    statusField: "status",
    dataModifiable: false,
    availableStatus: ["OPEN", "COMPLETED"],
    documents: {
      hide: true,
    },
    fields: {
      default: {
        detailView: [
          { field: "distributorReference", label: "Contract ID", readonly: true },
          { field: "customerReference", label: "Reference", readonly: true },
          { field: "purchaseDate", label: "Purchase date", type: "date", readonly: true },
          { field: "products", label: "Products", type: "list", readonly: true },
          { field: "paymentConditionEn", label: "Payment condition", readonly: true },
          { field: "incoterms", label: "Incoterms 2020", readonly: true },
          { field: "incotermsCity", label: "Incoterms city", readonly: true },
        ],
        overviewList: [
          { field: "distributorReference", headerName: "Contract ID", minWidth: 50, width: 125 },
          { field: "customerReference", headerName: "Reference", minWidth: 50, width: 125 },
          {
            field: "purchaseDate",
            headerName: "Purchase Date",
            minWidth: 50,
            width: 125,
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
          },
          {
            field: "products",
            headerName: "Products",
            width: 200,
            minWidth: 50,
            type: "list",
          },
          { field: "incoterms", headerName: "Incoterms 2020", width: 135, minWidth: 50 },
          { field: "incotermsCity", headerName: "Incoterms City", width: 135, minWidth: 50 },
          {
            field: "totalAmountLeftFrac",
            headerName: "Amount left",
            minWidth: 50,
            width: 100,
            progressBar: true,
          },
        ],
        childList: [],
      },
    },
  },
  contractPosition: {
    statusField: null,
    dataModifiable: false,
    availableStatus: ["OPEN", "COMPLETED"],
    documents: {
      hide: true,
    },
    fields: {
      default: {
        detailView: [
          {
            field: "contractDistributorReference",
            label: "Contract ID",
            readonly: true,
            link: {
              objectClass: "Contract",
              fieldRef: "contract",
            },
          },
          { field: "contractPosition", label: "Position", readonly: true },
          { field: "productNameEn", label: "Product", readonly: true },
          { field: "amount", label: "Contract amount", type: "number", readonly: true },
          {
            field: "amountLeft",
            label: "Amount left",
            type: "nonnegativeNumber",
            readonly: true,
          },
          { field: "amountUnit", label: "Amount unit", readonly: true },
          { field: "unitPrice", label: "Unit price", type: "number", readonly: true },
          { field: "totalPrice", label: "Total price", type: "number", readonly: true },
          { field: "totalPriceCurrency", label: "Currency", readonly: true },
        ],
        overviewList: [],
        childList: [
          {
            field: "contractPosition",
            headerName: "Pos.",
            type: "number",
            width: 70,
            align: "right",
          },
          {
            field: "productNameEn",
            headerName: "Product",
            minWidth: 90,
            width: 150,
          },
          {
            field: "productPurity",
            headerName: "Prod. Quality",
            minWidth: 90,
            width: 125,
          },
          {
            field: "productSize",
            headerName: "Prod. Size",
            minWidth: 90,
            width: 150,
          },
          {
            field: "productCountryOfOrigin",
            headerName: "Prod. Origin",
            minWidth: 90,
            width: 125,
          },
          {
            field: "amount",
            headerName: "Contr. Amount",
            minWidth: 50,
            width: 140,
            type: "number",
          },
          {
            field: "amountLeft",
            headerName: "Amount Left",
            minWidth: 50,
            width: 125,
            type: "nonnegativeNumber",
          },
          {
            field: "amountUnit",
            headerName: "Unit",
            minWidth: 50,
            width: 90,
          },
          {
            field: "unitPrice",
            headerName: "Unit Price",
            minWidth: 50,
            width: 100,
            type: "number",
          },
          {
            field: "totalPrice",
            headerName: "Total Price",
            minWidth: 50,
            width: 100,
            type: "number",
          },
          {
            field: "totalPriceCurrency",
            headerName: "Currency",
            minWidth: 50,
            width: 100,
          },
          {
            field: "productPackagingEn",
            headerName: "Packaging",
            minWidth: 50,
            width: 100,
          },
        ],
      },
    },
  },
  callOff: {
    statusField: "statusByDate",
    dataModifiable: false,
    availableStatus: ["DROPSHIPPING", "UPCOMING", "IN_TRANSIT", "DELIVERED"],
    documents: {
      hide: true,
    },
    fields: {
      default: {
        detailView: [
          {
            field: "contractDistributorReference",
            label: "Contract ID",
            readonly: true,
            link: {
              objectClass: "Contract",
              fieldRef: "contract",
            },
          },
          { field: "deliveryId", label: "Call-Off ID", readonly: true },
          { field: "customerReference", label: "Reference", readonly: true },
          {
            field: "commissionDate",
            label: "Commission Date",
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
            readonly: true,
          },
          { field: "statusByDate", label: "Status", type: "callOffStatus", readonly: true },
          { field: "products", label: "Products", type: "list", readonly: true },
          {
            field: "eta",
            label: "ETA",
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
            readonly: true,
          },
          { field: "incoterms", label: "Incoterms 2020", readonly: true },
          { field: "incotermsCity", label: "Incoterms City", readonly: true },
          {
            field: "etd",
            label: "ETD",
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
            readonly: true,
          },
          { field: "imo", label: "Ship IMO", readonly: true },
          { field: "shipName", label: "Ship Name", readonly: true },
          { field: "deliveryMethod", label: "Delivery Method", readonly: true },
          { field: "paymentConditionEn", label: "Payment Condition", readonly: true },
        ],
        overviewList: [
          {
            field: "contractDistributorReference",
            headerName: "Contract ID",
            minWidth: 50,
            width: 150,
          },
          {
            field: "deliveryId",
            headerName: "Call-Off ID",
            minWidth: 20,
            width: 110,
          },
          {
            field: "customerReference",
            headerName: "Reference",
            minWidth: 50,
            width: 150,
          },
          {
            field: "products",
            headerName: "Products",
            width: 200,
            minWidth: 50,
            type: "list",
          },
          {
            field: "eta",
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
            headerName: "ETA",
            minWidth: 50,
            width: 125,
          },
          {
            field: "deliveryMethod",
            headerName: "Delivery Method",
            minWidth: 50,
            width: 150,
          },
        ],
        childList: [
          {
            field: "deliveryId",
            headerName: "Call-Off ID",
            minWidth: 20,
            width: 110,
          },
          {
            field: "customerReference",
            headerName: "Reference",
            minWidth: 50,
            width: 150,
          },
          {
            field: "products",
            headerName: "Products",
            width: 200,
            minWidth: 50,
            type: "list",
          },
          {
            field: "eta",
            type: "date",
            valueGetter: (params) => params.value?.toJSDate(),
            headerName: "ETA",
            minWidth: 50,
            width: 125,
          },
          {
            field: "deliveryMethod",
            headerName: "Delivery Method",
            minWidth: 50,
            width: 150,
          },
          {
            field: "statusByDate",
            headerName: "Status",
            type: "callOffStatus",
            minWidth: 50,
            width: 150,
          },
        ],
      },
    },
  },
  callOffPosition: {
    statusField: null,
    dataModifiable: false,
    availableStatus: [],
    documents: {
      comingSoon: true,
    },
    fields: {
      default: {
        detailView: [],
        overviewList: [],
        childList: [
          {
            field: "callOffPosition",
            headerName: "Pos.",
            type: "number",
            width: 70,
            align: "right",
          },
          {
            field: "productNameEn",
            headerName: "Product",
            minWidth: 90,
            width: 150,
          },
          {
            field: "productPurity",
            headerName: "Prod. Quality",
            minWidth: 90,
            width: 125,
          },
          {
            field: "productSize",
            headerName: "Prod. Size",
            minWidth: 90,
            width: 150,
          },
          {
            field: "productCountryOfOrigin",
            headerName: "Prod. Origin",
            minWidth: 90,
            width: 125,
          },
          {
            field: "amount",
            headerName: "Contr. Amount",
            minWidth: 50,
            width: 140,
            type: "number",
          },
          {
            field: "amountUnit",
            headerName: "Unit",
            minWidth: 50,
            width: 90,
          },
          {
            field: "unitPrice",
            headerName: "Unit Price",
            minWidth: 50,
            width: 100,
            type: "number",
          },
          {
            field: "totalPrice",
            headerName: "Total Price",
            minWidth: 50,
            width: 100,
            type: "number",
          },
          {
            field: "totalPriceCurrency",
            headerName: "Currency",
            minWidth: 50,
            width: 100,
          },
          { field: "packagingEn", headerName: "Packaging", minWidth: 50, width: 100 },
          { field: "warehouse", headerName: "Warehouse", minWidth: 50, width: 100 },
        ],
      },
    },
  },
};

export default viewSettings;
