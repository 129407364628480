import React, { useEffect, useMemo } from "react";

import { Grid } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useDashboardState } from "@src/app.state";
import useAlerts from "@hooks/useAlerts";
import useApiData from "@hooks/useApiData";
import { generateApiPath } from "@src/utils/helpers";
import { CallOff } from "@src/types";
import { deserializeCallOff } from "@src/utils/typing";
import CallOffDetailView from "@components/CallOffDetailView/CallOffDetailView";
import { apiPaths, paths } from "@src/paths";
import VesselFinder from "@components/VesselFinder/VesselFinder";

const Details: React.FC = () => {
  const navigate = useNavigate();
  const {
    setBreadcrumbs,
    viewSettings: { apiVersion },
  } = useDashboardState();
  const alerts = useAlerts();

  const { id } = useParams();
  const { data, initialLoading, error } = useApiData(
    generateApiPath(apiPaths.callOffDetail, { apiVersion, id })
  );

  const callOff: CallOff = useMemo(() => deserializeCallOff(data), [data]);
  const imo = useMemo(() => (data && "imo" in data ? data.imo : undefined), [data]);
  const vesselFinder = useMemo(
    () => <VesselFinder imo={imo} showTrack title={"VesselFinder"} loading={false} />,
    [imo]
  );

  // Show fetching errors
  useEffect(() => {
    if (!error) {
      return;
    }
    alerts.notifyError({
      title: "Not found",
      content:
        error?.response?.status === 404
          ? `Could not find call-off with id ${id}.`
          : "Sorry, something went wrong. Please try again later.",
      autoHideDuration: 5000,
    });
    navigate(paths.callOffOverview);
  }, [error]);

  // Set breadcrumbs
  useEffect(
    () =>
      setBreadcrumbs([
        { label: "Call-Offs", url: paths.callOffOverview },
        {
          label: callOff ? `${callOff.contractDistributorReference}-${callOff.deliveryId}` : "...",
        },
      ]),
    [callOff]
  );

  return (
    <CallOffDetailView callOff={callOff} loading={initialLoading}>
      {imo && (
        <Grid container spacing={3} mt={0.5}>
          <Grid item xs={12} md={12} xl={12} sx={{ height: "400px" }}>
            {vesselFinder}
          </Grid>
        </Grid>
      )}
    </CallOffDetailView>
  );
};

export default Details;
